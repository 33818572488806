<template>
  <div class="treasury-banks-list fixed-draggable-dynamic-table-wrapper-height">
    <draggable-dynamic-table ref="banksList"
                             :data="data"
                             :columns="columnsLabel"
                             :options="options"
                             @filter:remove="setFilter($event, true)"
                             @filter:set="setFilter($event)"
                             @sort:set="setSort($event)"
                             @row:clicked="selectedBank = $event, showBankProfilePromptStatus = true"
                             @load:more="getBanks()"/>

    <!--  show bank details prompt  -->
    <vs-prompt
      class="medium-prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="showBankProfilePromptStatus"
      @close="showBankProfilePromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div v-if="checkUserPermissions('bank.update')" @click="editBankPromptStatus = true">
              <custom-icon color="warning"
                           icon="EDIT"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="prompt-title w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('treasury.banks.bank.dynamicTitle', {bank: selectedBank.name}) }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="showBankProfilePromptStatus = false">
              <custom-icon color="danger"
                           icon="TIMES-CIRCLE"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content py-2 px-3">
        <template>
          <keep-alive>
            <bank-menu :bank-id="selectedBank.id"/>
          </keep-alive>
        </template>
      </div>
    </vs-prompt>

    <!--  edit bank prompt  -->
    <vs-prompt
      class="prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="editBankPromptStatus"
      @close="editBankPromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div @click="handleClick('editBankBTN')">
              <custom-icon color="success"
                           icon="SAVE"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="prompt-title w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('treasury.banks.edit.dynamicTitle', {bank: selectedBank.name}) }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="editBankPromptStatus = false">
              <custom-icon color="danger"
                           icon="TIMES-CIRCLE"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content py-2 px-3">
        <template>
          <keep-alive>
            <edit-bank :bank-id="selectedBank.id" @edited="handleReloadBankList"/>
          </keep-alive>
        </template>
      </div>

    </vs-prompt>

    <!--  insert bank prompt  -->
    <vs-prompt
      class="prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="insertBankPromptStatus"
      @close="insertBankPromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div @click="handleClick('insertBankBTN')">
              <custom-icon color="success"
                           icon="SAVE"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="prompt-title w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('treasury.banks.insert.title') }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="insertBankPromptStatus = false">
              <custom-icon color="danger"
                           icon="TIMES-CIRCLE"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content py-2 px-3">
        <template>
          <keep-alive>
            <insert-bank :in-modal="true" @inserted="handleReloadBankList"/>
          </keep-alive>
        </template>
      </div>

    </vs-prompt>

    <button v-show="false"
            id="insertBank"
            @click="insertBankPromptStatus = true"/>
  </div>
</template>

<script>
import axios from 'axios'
import {getBanks, getBanksPrice} from '@/http/requests/banks'
import DraggableDynamicTable from '@/components/draggableDynamicTable/draggableDynamicTable'
import {addComma, checkUserPermissions} from "../../../../../assets/js/functions";
import CustomIcon from "../../../../../components/customIcon/customIcon";
import BankMenu from "../bank/menu/bankMenu";
import EditBank from "../bank/edit/editBank";
import InsertBank from "../insert/insertBank";

export default {
  name: 'banksList',
  metaInfo () {
    return {
      title: this.$t('treasury.banks.title')
    }
  },
  components: {InsertBank, EditBank, BankMenu, CustomIcon, DraggableDynamicTable},
  data () {
    return {
      requestSent: false,
      showBankProfilePromptStatus: false,
      insertBankPromptStatus: false,
      editBankPromptStatus: false,
      selectedBank: {},
      priceRequestSent: false,
      loadingTimer: 0,
      options: {
        id: 'banksList',
        rowKeyField: 'rowNumber'
      },
      columnsLabel: [
        {
          field: 'balance',
          i18n: 'treasury.banks.table.header.balance',
          width: 'calc(100% / 4)',
          minWidth: 130,
          sortable: true,
          filter: true,
          filterTypes: [
            {
              icon: 'equals',
              name: 'برابر باشد',
              i18n: 'draggableTable.filter.types.equals',
              id: 1
            },
            {
              icon: 'not-equal',
              name: 'برابر نباشد',
              i18n: 'draggableTable.filter.types.notEqual',
              id: 2
            },
            {
              icon: 'greater-than-equal',
              name: 'بزرگتر مساوی',
              i18n:
                'draggableTable.filter.types.greaterThanEquals',
              id: 3
            },
            {
              icon: 'less-than-equal',
              name: 'کوچکتر مساوی',
              i18n: 'draggableTable.filter.types.lessThanEquals',
              id: 4
            }
          ],
          // footer: {
          //   type: 'auto-sum',
          //   textType: 'price'
          // }
        },
        {
          field: 'card_number',
          i18n: 'treasury.banks.table.header.cardNumber',
          width: 'calc(100% / 4)',
          minWidth: 170,
          filter: true,
          filterTypes: [
            {
              icon: 'search',
              name: 'شامل شود',
              i18n: 'draggableTable.filter.types.search',
              id: 1
            }
          ],
          align: 'center',
          sortable: true
        },
        {
          field: 'account_number',
          i18n: 'treasury.banks.table.header.accountNumber',
          width: 'calc(100% / 4)',
          minWidth: 170,
          filter: true,
          filterTypes: [
            {
              icon: 'search',
              name: 'شامل شود',
              i18n: 'draggableTable.filter.types.search',
              id: 1
            }
          ],
          align: 'center',
          sortable: true
        },
        {
          field: 'name',
          i18n: 'treasury.banks.table.header.name',
          width: 'calc(100% / 4)',
          minWidth: 170,
          align: 'center',
          filter: true,
          filterTypes: [
            {
              icon: 'search',
              name: 'شامل شود',
              i18n: 'draggableTable.filter.types.search',
              id: 1
            }
          ],
          sortable: true,
          locked: true
        },
        {
          field: 'rowNumber',
          i18n: 'treasury.banks.table.header.row',
          width: '60px',
          minWidth: 60,
          align: 'center',
          // sortable: true,
          locked: true,
          footer: {}
        }
      ],
      data: [],
      page: 1,
      filters: [],
      sorts: [],
      endedList: false,
      actions: [
        {
          toolbar: [
            {
              id: 'insertBank',
              icon: 'icon-plus',
              iconPack: 'feather',
              color: 'success',
              permission: 'bank.create'
            }
          ],
          leftToolbar: [
            {
              id: 'printTable',
              // i18n: 'draggableDynamicTable.actions.print',
              icon: 'icon-printer',
              iconPack: 'feather'
            },
            {
              id: 'downloadTable',
              // i18n: 'draggableDynamicTable.actions.download',
              icon: 'icon-download',
              iconPack: 'feather'
            },
            {
              id: {name: 'treasuryBankTrash'},
              type: 'link',
              icon: 'icon-trash',
              iconPack: 'feather',
              permission: 'bank.delete'
            },
            {
              id: 'settingTable',
              icon: 'icon-settings',
              iconPack: 'feather'
            }
          ]
        }
      ]
    }
  },
  created () {
    setTimeout(() => {
      this.$store.dispatch('updateNavbarActions', this.actions[0])
      this.$store.dispatch('updateContentNavbarStyle', 'sticky')
    }, 50)

    this.getBanksPrice()
    this.getBanks()
  },
  methods: {
    addComma (val) {
      let sign = ''

      if (val < 0) sign = '-'

      val = val.toString()

      if (val[0] === '0') val = val.substr(1, val.length - 1)

      return val.toString().replace(/\D/g, '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + sign
    },
    checkUserPermissions(permission) {
      return checkUserPermissions(permission)
    },
    handleClick (id) {
      document.getElementById(id).click()
    },
    getBanks () {
      if (!this.requestSent) {
        this.requestSent = true

        clearTimeout(this.loadingTimer)
        this.loadingTimer = setTimeout(() => {
          if (!this.endedList) {
            if (this.$refs.banksList && this.data.length === 0) this.$refs.banksList.loadMoreStatus = 'FirstLoad'
            else if (this.$refs.banksList && this.data.length > 0) this.$refs.banksList.loadMoreStatus = 'Loading'

            getBanks(this.page, this.filters, this.sorts).then(response => {
              this.endedList = response.data.data.length === 0
              const banks = response.data.data
              // let banks = this.banks
              banks.forEach((bank) => {
                this.data.push({
                  // route: {
                  //   name: 'treasuryBank',
                  //   params: {id: bank.id}
                  // },
                  rowNumber: this.data.length + 1,
                  id: bank.id,
                  name: bank.name,
                  account_number: bank.account_number,
                  card_number: bank.card_number.toString().replace(/\B(?=(\d{4})+(?!\d))/g, '-'),
                  balance: {
                    type: 'price',
                    classes: bank.balance && bank.balance < 0 ? 'text-danger' : 'text-success',
                    value: bank.balance ? bank.balance : 0
                  }
                })
              })

              this.page = response.data.pagination.current_page + 1
              if (response.data.pagination.current_page === 1) {
                const row_index = this.columnsLabel.map((e) => {
                  return e.field
                }).indexOf('rowNumber')
                this.columnsLabel[row_index].footer.value = response.data.pagination.total
              }

              if (this.$refs.banksList) this.$refs.banksList.loadMoreStatus = ''

              this.requestSent = false
            }).catch(error => {
              if (axios.isCancel(error)) {
                this.$vs.notify({
                  title: this.$t('alert.duplicateRequest.title'),
                  text: this.$t('alert.duplicateRequest.message'),
                  icon: 'icon-alert-circle',
                  iconPack: 'feather',
                  time: 2400,
                  color: 'warning'
                })
              } else {

                if (this.$refs.banksList) this.$refs.banksList.loadMoreStatus = 'Danger'

                this.requestSent = false
              }
            })
          } else {
            this.requestSent = false
          }
        }, 400)
      }
    },
    setFilter (filters, remove) {
      const filters_list = []
      Object.keys(filters).forEach((key) => {
        switch (key) {

          case 'name':
            if (filters[key].search !== '') filters_list.push(`name=${  filters[key].search}`)
            break

          case 'account_number':
            if (filters[key].search !== '') filters_list.push(`account_number=${  filters[key].search}`)
            break

          case 'card_number':
            if (filters[key].search !== '') filters_list.push(`card_number=${  filters[key].search}`)
            break

          case 'balance':
            if (filters[key].search !== '') filters_list.push(`balance=${  filters[key].search  },${  filters[key].type.id}`)
            break
        }
      })

      this.data = []
      this.page = 1
      this.endedList = false
      this.filters = filters_list
      this.getBanks()
      this.getBanksPrice()
    },
    setSort (sorts) {
      const sorts_list = []
      Object.keys(sorts).forEach((key) => {
        switch (key) {

          case 'rowNumber':
            sorts_list.push(`order[0]=id,${  sorts[key] ? 'desc' : 'asc'}`)
            break

          case 'name':
            sorts_list.push(`order[0]=name,${  sorts[key] ? 'desc' : 'asc'}`)
            break

          case 'account_number':
            sorts_list.push(`order[0]=account_number,${  sorts[key] ? 'desc' : 'asc'}`)
            break

          case 'card_number':
            sorts_list.push(`order[0]=card_number,${  sorts[key] ? 'desc' : 'asc'}`)
            break

          case 'balance':
            sorts_list.push(`order[0]=virtual_balance,${  sorts[key] ? 'desc' : 'asc'}`)
            break
        }
      })

      this.data = []
      this.page = 1
      this.endedList = false
      this.sorts = sorts_list
      this.getBanks()
    },
    getBanksPrice () {
      if (!this.priceRequestSent) {
        this.priceRequestSent = true
        getBanksPrice().then(response => {
          this.priceRequestSent = false
          const price_index = this.columnsLabel.map((e) => {
            return e.field
          }).indexOf('balance')
          this.columnsLabel[price_index].footer = {
            value: addComma(response.data.data.total)
          }
        })
          .catch(() => {
            this.priceRequestSent = false
          })
      }
    },
    handleReloadBankList () {
      this.insertBankPromptStatus = false
      this.editBankPromptStatus = false
      this.showBankProfilePromptStatus = false
      this.data = []
      this.page = 1
      this.endedList = false
      this.getBanks()
      this.getBanksPrice()
    }
  }
}
</script>

<style scoped>

</style>
